/* eslint-disable */
export const templateFaqData = {
    init() {
        // JavaScript to be fired on all pages

        let hash = window.location.hash;
        if(hash.indexOf('scrollto') === -1){
            if(hash) {
                $(hash).parent().find('.collapse').collapse();
            }
        }

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

