export const headerPage = {
    init() {
        $(document).ready(function() {
            $('.header__menu__btn').on('click',  function () {
                $('body').toggleClass('header__menu__open')
            })
            $('.header__menu .menu-item-has-children').append('<span class="menu-item-touch"></span>')
            $('.menu-item-touch').on('click', function () {
                $('.menu-item').removeClass('open')
                $(this).parent('.menu-item').toggleClass('open')
            })

            if ($(window).width() > 767) {
                $(window).scroll(function () {
                    let scroll = $(window).scrollTop();
                    if (scroll > 0) {
                        $('.header').addClass('header--fix');
                    } else {
                        $('.header').removeClass('header--fix');
                    }
                });
            }

            else {
                $(window).scroll(function () {
                    let scrollheadertop = $(window).scrollTop();
                    if (scrollheadertop > 0) {
                        $('.header__top').addClass('header__top--static');
                    } else {
                        $('.header__top').removeClass('header__top--static');
                    }
                });
            }

            $('.header__search__toggle').on('click', function () {
                $('.header__search').toggleClass('open')
                $('.header__search__input input').focus()
            });
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
