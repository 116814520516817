export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('[data-toggle="tooltip"]').tooltip();

        $('.slick-slider').css('opacity', '1');

        if ($(window).width() < 992) {
            // $('.front__news .row').slick({
            //     dots: false,
            //     infinite: true,
            //     speed: 500,
            //     fade: false,
            //     cssEase: 'linear',
            //     autoplay: true,
            //     slidesToShow: 2,
            //     draggable: true,
            //     arrows: true,
            //     prevArrow: '<span class="slick--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M231.536 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113L238.607 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"/></svg></span>',
            //     nextArrow: '<span class="slick--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"/></svg></span>',
            // });
        }


        $('.template-faq-adviseur-data a.sidebar__link__item[href*="#"]:not([href="#"]), .template-faq-data a.sidebar__link__item[href*="#"]:not([href="#"])').on('click',function(e) {
            e.preventDefault();
            var offset = 0;
            var target = this.hash;
            if ($(this).data('offset') != undefined) offset = $(this).data('offset');
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset,
            }, 800, 'swing', function() {
                // window.location.hash = target;
            });
        });


        /* eslint-disable */
        function isOverflown(element) {
            return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
        }


        if ($('.table__scroller')[0]){

            let el = document.getElementsByClassName('table__scroller');
            let parentEl = el[0].parentNode;

            if (isOverflown(el[0])) {
                parentEl.classList.add('touch');
            } else {
                parentEl.classList.remove('touch');
            }

            $('.table__wrapper__touch').on('click touchstart touch', function () {
                $(this).parent().removeClass('touch');
            })
        }

        /* eslint-enable */

        // Mobile Footermenu toggle
        $('.footer__block__title').click(function () {
            $('.footer__block').removeClass('footer__menu--open')
            var clicks = $(this).data('clicks');
            if (clicks)
                $(this).parent('.footer__block').removeClass('footer__menu--open');
            else
                $(this).parent('.footer__block').addClass('footer__menu--open');
            $(this).data('clicks', !clicks);
        });

        if ($(window).width() > 991) {
            $('.single__sidebar').theiaStickySidebar({
                additionalMarginTop: 120,
            });
        }

        $( '.search-field' ).focus(function() {
            $(this).parent('.header__search__input').addClass('header__search__input--focused');
        });

        $( '.search-field' ).blur(function() {
            $(this).parent('.header__search__input').removeClass('header__search__input--focused');
        });

        $('.info__icon').click(function () {
            $(this).tooltip('show')
        });

        $('.table__scroller').scroll(function() {
            $('[data-toggle="tooltip"]').tooltip('hide');
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
